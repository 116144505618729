import React from "react";
import Layout from "../../components/Layout";
import { graphql, Link } from "gatsby";
import { getTitleFromPath, order } from "../../components/Sidebar";

export default function Index({ data, location }) {
  const foundations = data.allSitePage.nodes;

  return (
    <Layout
      location={location}
      breadcrumb1="Home"
      breadcrumb2="Foundations"
      title="Foundations"
      description="These design principles are the base and the scaffolding for building our products. We’ve crafted these foundations to inform and inspire Nielsen designers to create great products."
    >
      <div className="styled-grid">
        <div className="row">
          {order(foundations).map(
            (page) =>
              page.pageContext.type === "foundations" && (
                <Link
                  to={page.path.toLowerCase()}
                  activeClassName="active"
                  key={page.id}
                  className="col-md-4 mb-space-500"
                >
                  <div className="clickable-card">
                    <div
                      className="styled-grid gap-space-300"
                      style={{
                        minHeight: "96px",
                        alignContent: "space-between",
                      }}
                    >
                      <gds-display-icon
                        icon="export"
                        size="20"
                        foreground-level="400"
                        style={{
                          gridColumnStart: "2",
                          color: "var(--gds-gray-800)",
                        }}
                      ></gds-display-icon>
                      <div className="gds-size-500-semibold">
                        {page.path === "/foundations/writing/"
                          ? "Writing Guidelines"
                          : getTitleFromPath(page.path)}
                      </div>
                    </div>
                  </div>
                </Link>
              )
          )}
        </div>
      </div>
    </Layout>
  );
}

//export components list query
export const query = graphql`
  query FoundationList {
    allSitePage {
      nodes {
        path
        pageContext
      }
    }
  }
`;
